/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cursor_help {
  cursor: help;
}
.cursor_pointer {
  cursor: pointer;
}
.text-center {
  text-align: center;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}
